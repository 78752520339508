// Stylesheets
import '../scss/main.scss';

// App utils
import App from './app';

// Vendors
import './vendors/rne-assets';
import './vendors/select2';
import './vendors/viewerjs';

// Behaviors
import './behaviors/card-record-history';
import './behaviors/checkbox-collapse-toggler';
import './behaviors/form-autosubmit';
import './behaviors/form-mode';
import './behaviors/input-rut';
import './behaviors/input-tab';
import './behaviors/input-time-picker';
import './behaviors/nav-link-with-links';
import './behaviors/sections-tree';
import './behaviors/shortcut-tab';
import './behaviors/scroll-to-top';
import './behaviors/status';
import './behaviors/modals';

$(() => {
  $('form').submit((e) => {
    const form = e.currentTarget;
    const submitButtons = [...form.elements].filter((element) => (
      element.matches('[type="submit"]:not(.js-do-not-disable-on-submit)')
    ));

    // Disable buttons after submit to prevent disabling submit inputs
    // with values
    submitButtons.forEach((submitButton) => {
      submitButton.classList.add('disabled');
      submitButton.disabled = true; // eslint-disable-line no-param-reassign
    });

    // Show loading icon
    App.utils.showLoading($(submitButtons));

    return true;
  });
});
