// Utils
import App from '../app';

// Constants
const SHORTCUT_TAB_ACTIVE_CLASS = 'active';

// Selectors
const SHORTCUT_TAB_SELECTOR = '[data-toggle="shortcuttab"]';

// Functions
/**
 * Adds `.active` class to `shortcutTab` and set `aria-selected` attribute
 * to `true`.
 * @param  {HTMLElement} shortcutTab
 * @return {void}
 */
function activateShortcutTab(shortcutTab) {
  shortcutTab.classList.add(SHORTCUT_TAB_ACTIVE_CLASS);
  shortcutTab.setAttribute('aria-selected', true);
}

/**
 * Removes `.active` class to `shortcutTab` and set `aria-selected` attribute
 * to `false`.
 * @param  {HTMLElement} shortcutTab
 * @return {void}
 */
function deactivateShortcutTab(shortcutTab) {
  shortcutTab.classList.remove(SHORTCUT_TAB_ACTIVE_CLASS);
  shortcutTab.setAttribute('aria-selected', false);
}

/**
 * Bind event listeners to `shortcutTab`'s tab to activate or deactivate
 * `shortcutTab`.
 * @param  {HTMLElement} shortcutTab
 * @return {void}
 */
function initShortcutTab(shortcutTab) {
  // Get tab
  const tabSelector = shortcutTab.getAttribute('href')
    || shortcutTab.dataset.target;
  const tabHash = tabSelector.slice(1);
  const tab = document.getElementById(tabHash);

  if (!tab) return;

  // Update shortcutTab state when tab is shown or hidden
  $(tab).on('shown.bs.tab', () => {
    activateShortcutTab(shortcutTab);
  });
  $(tab).on('hidden.bs.tab', () => {
    deactivateShortcutTab(shortcutTab);
  });

  // On click shortcutTab, tab should be shown
  shortcutTab.addEventListener('click', (e) => {
    e.preventDefault();
    shortcutTab.blur();

    // Activate tab
    $(tab).tab('show');

    // Scroll to tab panel container
    const tabPanelSelector = tab.getAttribute('href') || tab.dataset.target;
    const tabPanelId = tabPanelSelector.slice(1); // Removes '#' from string
    const tabPanel = document.getElementById(tabPanelId);

    if (tabPanel) {
      App.utils.scrollToElement(tabPanel.parentNode);
    }
  });
}

// Initialize behavior
window.addEventListener('load', () => {
  document
    .querySelectorAll(SHORTCUT_TAB_SELECTOR)
    .forEach(initShortcutTab);
});
