// Selectors
const CHECKBOX_COLLAPSE_TOGGLER_SELECTOR = '.checkbox-collapse-toggler';
const ENABLED_SELECTOR = ':enabled';
const ENABLED_FORM_CONTROLS_SELECTOR = `
  input${ENABLED_SELECTOR},
  select${ENABLED_SELECTOR},
  textarea${ENABLED_SELECTOR}
`;

// Functions
/**
 * Add `.show` class to `collapse`.
 * @param  {HTMLElement} collapse
 * @return {void}
 */
function openCollapse(collapse) {
  collapse.classList.add('show');
}

/**
 * Opens corresponding `checkbox`'s target elements, following the next rules:
 * 1. All target elements will be opened if `checkbox` is `checked`.
 * 2. Else, a target element will be opened if it has some input element with value.
 * @param  {HTMLElement} checkbox
 * @return {void}
 */
function openCheckboxCollapseTogglerTargets(checkbox) {
  const targets = [...document.querySelectorAll(checkbox.dataset.target)];

  // Open all target elements if checkbox is checked
  if (checkbox.checked) {
    checkbox.setAttribute('aria-expanded', true);
    targets.forEach(openCollapse);
    return;
  }

  // Open target elements that has some input with value
  const targetsWithSomeInputWithValue = targets.filter((target) => {
    const inputs = [...target.querySelectorAll(ENABLED_FORM_CONTROLS_SELECTOR)];

    return inputs.some((input) => {
      const inputType = input.getAttribute('type');

      // If input is a checkbox or a radio, check if it is checked instead
      // of its value
      if (['radio', 'checkbox'].includes(inputType)) {
        return input.checked;
      }

      return input.value;
    });
  });

  if (targetsWithSomeInputWithValue.length) {
    checkbox.checked = true; // eslint-disable-line no-param-reassign
    checkbox.setAttribute('aria-expanded', true);
    targetsWithSomeInputWithValue.forEach(openCollapse);
  }
}

// Initialize behavior
$(() => {
  document
    .querySelectorAll(CHECKBOX_COLLAPSE_TOGGLER_SELECTOR)
    .forEach(openCheckboxCollapseTogglerTargets);
});
