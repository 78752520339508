// Selectors
const FORM_AUTOSUBMIT_SELECTOR = '.form-autosubmit';
const FORM_CONTROLS_SELECTOR = 'input, textarea, select';

// Constants
const FORM_CONTROL_NOT_AUTOSUBMIT_CLASS = 'no-autosubmit-on-change';

// Initialize behavior
$(() => {
  document
    .querySelectorAll(FORM_AUTOSUBMIT_SELECTOR)
    .forEach((form) => {
      const controls = [...form.querySelectorAll(FORM_CONTROLS_SELECTOR)];
      controls
        .filter((control) => !control.classList.contains(FORM_CONTROL_NOT_AUTOSUBMIT_CLASS))
        .forEach((control) => {
          const controlIsDateTimePicker = control.classList.contains('datetimepicker-input');

          if (controlIsDateTimePicker) {
            $(control).on('change.datetimepicker', ({ oldDate }) => {
              // NOTE: `oldDate` is `null` when the event was triggered after
              // datetimepicker initialization
              if (oldDate === null) return;

              form.submit();
            });
          } else {
            $(control).change(() => {
              form.submit();
            });
          }
        });
    });
});
