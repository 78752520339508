// Utils
import App from '../app';

// Constants
const COLLAPSE_CLASS = 'collapse';
const COLLAPSE_ACTIVE_CLASS = 'show';
const TAB_PANEL_CLASS = 'tab-pane';
const TAB_PANEL_ACTIVE_CLASS = 'active';

// Selectors
const SECTIONS_TREE_SELECTOR = '.sections-tree';
const SECTIONS_TREE_ELEMENT_SELECTOR = `
  ${SECTIONS_TREE_SELECTOR} .${COLLAPSE_CLASS},
  ${SECTIONS_TREE_SELECTOR} .${TAB_PANEL_CLASS}
`;
const SECTIONS_TREE_ELEMENT_PARENTS_TO_ACTIVATE_SELECTOR = `
  .${COLLAPSE_CLASS}:not(.${COLLAPSE_ACTIVE_CLASS}),
  .${TAB_PANEL_CLASS}:not(.${TAB_PANEL_ACTIVE_CLASS})
`;
const SECTIONS_TREE_ELEMENT_LINK_SELECTOR = '.sections-tree-element-link';

// Functions
/**
 * Activates `sectionTreeElement` element and its parents if they are not activated yet.
 * @param  {HTMLElement} sectionsTreeElement
 * @return {void}
 */
function activateSectionsTreeElement(sectionsTreeElement) {
  const sectionsTreeElementIsValid = sectionsTreeElement.matches(
    SECTIONS_TREE_ELEMENT_SELECTOR
  );

  // Early return in case sectionsTreeElement is not valid
  if (!sectionsTreeElementIsValid) return;

  // Activate sectionsTreeElement and its parents
  const sectionsTreeElementContainer = sectionsTreeElement.parentNode;
  const $sectionsTreeElement = $(sectionsTreeElement);
  const $sectionsTreeElementParents = $sectionsTreeElement.parents(
    SECTIONS_TREE_ELEMENT_PARENTS_TO_ACTIVATE_SELECTOR
  );
  const $elementsToActivate = $sectionsTreeElement
    .not(`.${COLLAPSE_ACTIVE_CLASS}, .${TAB_PANEL_ACTIVE_CLASS}`)
    .add($sectionsTreeElementParents);

  // If there are no elements to activate, sectionsTreeElement is visible,
  // so just scroll to sectionsTreeElementContainer immediately
  if (!$elementsToActivate.length) {
    App.utils.scrollToElement(sectionsTreeElementContainer);
    return;
  }

  // Activate elements
  $elementsToActivate
    .each((index, element) => {
      const isLastElement = index === ($elementsToActivate.length - 1);
      const elementIsCollapse = element.classList.contains(COLLAPSE_CLASS);

      if (elementIsCollapse) {
        // Scroll to sectionsTreeElementContainer after last element is activated
        if (isLastElement) {
          const shownCollapseEvent = 'shown.bs.collapse';
          const onShownCollapseHandler = () => {
            App.utils.scrollToElement(sectionsTreeElementContainer);

            // Removes event listener immediately, so page will not scroll
            // to sectionsTreeElementContainer whenever this tab is activated.
            $(element).off(shownCollapseEvent, onShownCollapseHandler);
          };

          $(element).on(shownCollapseEvent, onShownCollapseHandler);
        }

        // Activate collapse
        $(element).collapse('show');
      } else {
        // Element is a tab panel, activate its associated tab
        const tabPanelId = element.id;
        const tab = document.querySelector(`
          [data-toggle="tab"][href="#${tabPanelId}"],
          [data-toggle="tab"][data-target="#${tabPanelId}"]
      `);

        // Scroll to sectionsTreeElementContainer after last element is activated
        if (isLastElement) {
          const shownTabEvent = 'shown.bs.tab';
          const onShownTabHandler = () => {
            App.utils.scrollToElement(sectionsTreeElementContainer);

            // Removes event listener immediately, so page will not scroll
            // to sectionsTreeElementContainer whenever this tab is activated.
            $(tab).off(shownTabEvent, onShownTabHandler);
          };

          $(tab).on(shownTabEvent, onShownTabHandler);
        }

        // Activate tab
        $(tab).tab('show');
      }
    });
}

/**
 * If page has a hash, activates the sections tree element with id equals to
 * the hash and its parents.
 * @return {void}
 */
function activateHashSectionsTreeElement() {
  const { hash } = window.location;

  // Early return in case there is no hash on page
  if (!hash) return;

  const hashCleaned = hash.slice(1); // Removes '#' from string
  const hashElement = document.getElementById(hashCleaned);

  activateSectionsTreeElement(hashElement);
}

// Initialize behavior
window.addEventListener('load', () => {
  const sectionsTree = document.querySelector(SECTIONS_TREE_SELECTOR);

  // Early return in case there is no sections tree in DOM
  if (!sectionsTree) return;

  // If there is a sections tree element with id equals to the hash,
  // activate it and its parents
  activateHashSectionsTreeElement();

  // On click links to a sections tree element, activate it and its parents
  document
    .querySelectorAll(SECTIONS_TREE_ELEMENT_LINK_SELECTOR)
    .forEach((link) => {
      link.addEventListener('click', (e) => {
        // Prevent default behavior so when element is visible on screen,
        // page will not scroll immediatly
        e.preventDefault();
        // Update hash
        window.history.replaceState({}, '', link.href);

        activateHashSectionsTreeElement();
      });
    });
});
