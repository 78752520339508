// Constants
const FORM_EDIT_MODE_CLASS = 'editing';
const FORM_CONTROL_INVALID_CLASS = 'is-invalid';

// Selectors
const FORM_MODE_SELECTOR = 'form.form-mode';
const EDIT_TOGGLER_SELECTOR = '.form-mode-toggle-edit';
const READ_TOGGLER_SELECTOR = '.form-mode-toggle-read';

// Functions
/**
 * Adds `editing` class to `form` and enables its fields if `toggleEdit` is `true`,
 * removes `editing` class from `form` and disables its fields otherwise.
 * @param {HTMLElement} form
 * @param {Boolean} toggleEdit
 * @return {void}
 */
function toggleFormMode(form, toggleEdit = true) {
  // Update form controls attributes
  const formControls = Array.from(form.elements)
    .filter((formControl) => !formControl.matches('button, [type="hidden"]'))
    .filter((formControl) => formControl.dataset.readonly !== 'true');

  if (toggleEdit) {
    formControls.forEach((formControl) => {
      // Enable form control
      formControl.removeAttribute('disabled');
    });
  } else {
    formControls.forEach((formControl) => {
      // Disable form control
      formControl.setAttribute('disabled', true);

      // Remove invalid status and feedback
      formControl.classList.remove(FORM_CONTROL_INVALID_CLASS);

      const formControlInvalidFeedbackId = `${formControl.id}_error`;
      const formControlInvalidFeedback = document.getElementById(
        formControlInvalidFeedbackId
      );

      if (formControlInvalidFeedback) {
        formControlInvalidFeedback.remove();
      }

      // Restore form control last value
      const { lastValue } = formControl.dataset;
      const { value } = formControl;
      const shouldRestoreLastValue = value !== lastValue;

      if (shouldRestoreLastValue) {
        if (formControl.matches('[data-toggle="datetimepicker"]')) {
          const { format } = formControl.dataset;
          const date = moment(lastValue).format(format);

          formControl.value = date; // eslint-disable-line no-param-reassign
          return;
        }

        // NOTE: Use jquery val() function for select2 compatibility
        $(formControl).val(lastValue).trigger('change');
      }
    });
  }

  // Hide opened tooltips
  let querySelector = '.show-on-edit-mode';
  if (toggleEdit) {
    querySelector = '.show-on-read-mode';
  }
  form.querySelectorAll(querySelector).forEach((element) => {
    element.querySelectorAll("[data-tooltip='true'").forEach((elementToggle) => {
      $(elementToggle).tooltip('hide');
    });
  });

  // Adds or removes edit mode class
  form.classList.toggle(FORM_EDIT_MODE_CLASS, toggleEdit);
}

/**
 * Binds click event to `toggler` for toggling `form` mode.
 * Activates edit mode if `toggleEdit` is `true`, deactivates edit mode otherwise.
 * @param {HTMLElement} toggler
 * @param {HTMLElement} form
 * @param {Boolean} toggleEdit
 * @return {void}
 */
function initFormModeToggler(toggler, form, toggleEdit = true) {
  toggler.addEventListener('click', () => {
    toggleFormMode(form, toggleEdit);
  });
}

/**
 * Binds necessary events to buttons for toggling modes to `form`.
 * @param {HTMLElement} form
 * @return {void}
 */
function initFormMode(form) {
  // Toggle to edit mode on click edit toggler
  form
    .querySelectorAll(EDIT_TOGGLER_SELECTOR)
    .forEach((editToggler) => {
      initFormModeToggler(editToggler, form);
    });

  // Toggle to read mode on click read toggler
  form
    .querySelectorAll(READ_TOGGLER_SELECTOR)
    .forEach((readToggler) => {
      initFormModeToggler(readToggler, form, false);
    });

  // Check the initial mode
  const formInitsInEditMode = form.classList.contains(FORM_EDIT_MODE_CLASS);
  toggleFormMode(form, formInitsInEditMode);
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll(FORM_MODE_SELECTOR)
    .forEach(initFormMode);
});
