// Utils
import App from '../app';

// Selectors
const RECORD_HISTORY_LIST_SELECTOR = '.record-history-list';
const RECORD_HISTORY_LIST_CONTAINER_SELECTOR = '.record-history-list-container';

// Constants
const DESKTOP_BREAKPOINT_WIDTH = 992;
const RECORD_HISTORY_LIST_ITEM_MAX_HEIGHT_IN_REM = 9.875;
const RECORD_HISTORY_LIST_DEFAULT_HEIGHT = `
  ${RECORD_HISTORY_LIST_ITEM_MAX_HEIGHT_IN_REM * 2.5}rem
`;

const SHOWN_TAB_EVENT = 'shown.bs.tab';

// Functions
/**
 * Resizes `list` so `container` is the same height as its reference element,
 * which selector is in `data-reference` attribute.
 * @param {HTMLElement} list
 * @param {HTMLElement} container
 * @return {void}
 */
function resizeRecordHistoryList(list, container) {
  const windowIsMobile = window.innerWidth < DESKTOP_BREAKPOINT_WIDTH;

  // Get reference element
  const referenceSelector = container.dataset.reference;
  const reference = document.querySelector(referenceSelector);

  // Define list height
  let listHeight = RECORD_HISTORY_LIST_DEFAULT_HEIGHT;

  if (!windowIsMobile && reference) {
    // Hide list to get container height without list
    list.style.display = 'none'; // eslint-disable-line no-param-reassign

    // Calculate height
    const containerHeightInPx = container.offsetHeight;
    const referenceHeightInPx = reference.offsetHeight;
    const listHeightInPx = referenceHeightInPx - containerHeightInPx;

    if (listHeightInPx > 0) {
      listHeight = `${listHeightInPx}px`;
    }
  }

  // Set height and other properties to enable scroll
  list.style.overflow = 'auto'; // eslint-disable-line no-param-reassign
  list.style.height = listHeight; // eslint-disable-line no-param-reassign
  list.style.display = 'block'; // eslint-disable-line no-param-reassign
}

/**
 * Resizes the lists that matches `RECORD_HISTORY_LIST_SELECTOR` and are inside
 * an element that matches `RECORD_HISTORY_LIST_CONTAINER_SELECTOR`.
 * @return {void}
 */
function resizeRecordHistoryLists() {
  document
    .querySelectorAll(RECORD_HISTORY_LIST_CONTAINER_SELECTOR)
    .forEach((container) => {
      const list = container.querySelector(RECORD_HISTORY_LIST_SELECTOR);

      if (!list) return;

      // Check if container is inside a tab panel or not:
      // 1. If it is and it is inactive, list will resize when the associated
      // tab is activated.
      // 2. Otherwise, list will resize immediately.
      const tabPanelParent = $(container).parents('.tab-pane').get(0);
      const shouldResizeListOnShownTab = tabPanelParent
        && !tabPanelParent.classList.contains('show');

      if (shouldResizeListOnShownTab) {
        const tab = App.utils.getTabPanelTab(tabPanelParent);
        const onShownTabHandler = () => {
          resizeRecordHistoryList(list, container);

          // Removes event listener immediately, so page will not resize list
          // whenever the tab is activated.
          $(tab).off(SHOWN_TAB_EVENT, onShownTabHandler);
        };

        // Resize list when tab is activated
        $(tab).on(SHOWN_TAB_EVENT, onShownTabHandler);
      } else {
        resizeRecordHistoryList(list, container);
      }
    });
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const recordHistory = document.querySelector(RECORD_HISTORY_LIST_SELECTOR);

  if (recordHistory) {
    window.addEventListener('load', resizeRecordHistoryLists);
    window.addEventListener('resize', resizeRecordHistoryLists);
  }
});
