import Viewer from 'viewerjs';

// Selectors
const VIEWER_SELECTOR = '.image-viewer';

// Set defaults
Viewer.setDefaults({
  toolbar: {
    zoomIn: 1,
    zoomOut: 1,
    oneToOne: 1,
    reset: 1,
    rotateLeft: 1,
    rotateRight: 1,
    flipHorizontal: 1,
    flipVertical: 1
  }
});

$(() => {
  document
    .querySelectorAll(VIEWER_SELECTOR)
    .forEach((element) => {
      new Viewer(element); // eslint-disable-line no-new
    });
});
