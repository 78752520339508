// Selectors
const NAV_LINK_WITH_LINKS_TAB_SELECTOR = '.nav-link-with-links[data-toggle="tab"]';
const COLLAPSE_SELECTOR = '.collapse';

// Functions
/**
 * Initialize nav link with links tab behavior
 * @param {HTMLElement} tab
 * @return {void}
 */
function initNavLinkWithLinksTab(tab) {
  // If tab has a collapse, then open it whenever the tab is activated
  const tabCollapse = tab.querySelector(COLLAPSE_SELECTOR);

  if (tabCollapse) {
    $(tab).on('shown.bs.tab', () => {
      $(tabCollapse).collapse('show');
    });
  }
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll(NAV_LINK_WITH_LINKS_TAB_SELECTOR)
    .forEach(initNavLinkWithLinksTab);
});
