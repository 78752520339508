// Selectors
const SELECT_WITH_OPTIONS_SOURCE_SELECTOR = 'select[data-options-source]:not(.js-not-select2)';

// Functions

/**
 * Fetch all data from url in optionsSource
 * @param {String} optionsSource
 * @returns {Array}
 */
async function getData(optionsSource) {
  const data = $.ajax({
    url: optionsSource,
    dataType: 'json'
  })
    .then((response) => response.results);

  return data;
}

/**
 * Fetch data filtered by serch term from url in optionsSource
 * @param {String} optionsSource
 * @param {String} dataToSearch
 * @returns {Array}
 */
async function getDataBySearchTerm(optionsSource, dataToSearch) {
  const url = `${optionsSource}?&search-term=${dataToSearch}`;
  const data = $.ajax({
    url,
    dataType: 'json'
  })
    .then((response) => response.results);

  return data;
}

/**
 * Fetch data filtered by id from url in optionsSource
 * @param {String} optionsSource
 * @param {String} dataToSearch
 * @returns {Array}
 */
async function getDataById(optionsSource, dataToSearch) {
  const url = `${optionsSource}?&id=${dataToSearch}`;
  const data = $.ajax({
    url,
    dataType: 'json'
  })
    .then((response) => response.results);

  return data;
}

/**
 * Appends options in select
 * @param {HTMLElement} $select
 * @param {Array} data
 */
function fillOptionsSelect($select, data) {
  data.filter((result) => !$select.find(`option[value="${result.id}"]`).length)
    .forEach((result) => {
      const newOption = new Option(
        result.gloss,
        result.id,
        false,
        false
      );
      $select.append(newOption);
    });
}

/**
 * Fetchs data from the url in `[data-options-source]` and appends it as options
 * to `select`.
 * @param {HTMLElement} select
 * @returns {void}
 */
async function initSelectWithOptionsSource(select) {
  const $select = $(select);
  const optionsSource = $select.data('optionsSource');
  if (!optionsSource) return;

  if ($select.attr('id') === 'id_users') {
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    const users = urlParams.get('users');

    if (users !== null) {
      const data = await getDataById(optionsSource, users);
      data.filter((result) => result.id === Number(users))
        .forEach((result) => {
          const newOption = new Option(
            result.gloss,
            result.id,
            false,
            false
          );
          $select.append(newOption);
        });
      $select.removeClass('is-invalid');
      $select.val(users);
    }

    $select.on('select2:open', () => {
      const inputSearch = $('.select2-search__field');
      $(inputSearch).on('input', async (e) => {
        const data = await getDataBySearchTerm(optionsSource, e.target.value);
        fillOptionsSelect($select, data);
      });
    });
  } else {
    const data = await getData(optionsSource);
    fillOptionsSelect($select, data);
  }
}

// Initialize behavior
$(() => {
  document
    .querySelectorAll(SELECT_WITH_OPTIONS_SOURCE_SELECTOR)
    .forEach(initSelectWithOptionsSource);
});
