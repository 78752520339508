const App = {};

App.utils = {
  hideLoading() {
    $('body').removeClass('wait');
  },

  thousandSeparator(x) {
    return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },

  showLoading($element) {
    $('body').addClass('wait');

    if ($element && $element.find('.page-loading-icon').length === 0) {
      $element.append(
        '<span class="icon spinner-border page-loading-icon" aria-hidden="true"></span>'
      );
    }
  },

  highlight($element) {
    $element.addClass('highlight');

    setTimeout(() => {
      $element.toggleClass('dim highlight');
    }, 15);

    setTimeout(() => $element.removeClass('dim'), 1010);
  },

  capitalize(string) {
    const str = String(string);

    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  scrollToElement(element) {
    // Get element top position
    const $element = $(element);
    const elementTopPosition = $element.offset().top;

    // Get sticky elements heights
    const $elementLastParent = $element.parents().last();
    const $elementLastParentStickyElements = $elementLastParent
      .find('[class*="sticky-top"]')
      .not('.sticky-top-link-to-top, .btn-icon-group');
    const stickyElementsHeightSum = [...$elementLastParentStickyElements]
      .reduce((sum, stickyElement) => sum + stickyElement.offsetHeight, 0);

    // Scroll page
    $('html, body').animate({
      scrollTop: elementTopPosition - stickyElementsHeightSum
    });
  },

  getTabPanelTab(tabPanel) {
    const tabPanelId = tabPanel.id;

    return document.querySelector(`
      [data-toggle="tab"][href="#${tabPanelId}"],
      [data-toggle="tab"][data-target="#${tabPanelId}"]
    `);
  }
};

export default App;
