// Selectors
const INPUT_TAB_SELECTOR = '.input-tab';

// Functions
/**
 * Shows selected tab's tab panel of `inputTab`
 * @param {HTMLInputElement|HTMLSelectElement} inputTab
 * @return {void}
 */
function showInputTab(inputTab) {
  // Get tab panel element
  const targetTabId = $(inputTab).val();
  const targetTab = document.getElementById(targetTabId);

  if (!targetTab) return;

  // Show tab panel
  $(targetTab).tab('show');
}

// Initialize behavior
$(() => {
  document
    .querySelectorAll(INPUT_TAB_SELECTOR)
    .forEach((inputTab) => {
      $(inputTab).change(() => {
        showInputTab(inputTab);
      });

      showInputTab(inputTab);
    });
});
