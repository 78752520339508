$(() => {
  $('.datetimepicker-input').each((i, item) => {
    const $this = $(item);
    const format = $this.data('format');
    const date = moment($this.val(), format);

    $this.data('target', `#${$this.prop('id')}`);

    $this.datetimepicker({
      date,
      format,
      locale: 'es',
      useCurrent: false,
      icons: {
        time: 'datepicker-icon-time',
        date: 'datepicker-icon-date',
        up: 'datepicker-icon-up',
        down: 'datepicker-icon-down',
        previous: 'datepicker-icon-previous',
        next: 'datepicker-icon-next',
        today: 'datepicker-icon-today',
        clear: 'datepicker-icon-clear',
        close: 'datepicker-icon-closee'
      }
    });
  });
});
