const MODAL_BTN_SELECTOR = '[data-toggle="modal"]';

function initModals(btn) {
  const $btn = $(btn);
  $btn.on('click', () => {
    $btn.blur();
    const visualizerId = $btn.attr('extra-data');
    const section = $(`[id=${visualizerId}]`)[0];
    section.classList.add('show');
  });
}

// Initialize behavior
$(() => {
  document
    .querySelectorAll(MODAL_BTN_SELECTOR)
    .forEach(initModals);
});
