// Constants
const SCROLL_TO_TOP_VISIBLE_CLASS = 'show';

// Selectors
const SCROLL_TO_TOP_SELECTOR = '.scroll-to-top';

// Functions
/**
 * Shows scroll to top element when page is not at the top. Otherwise, hides it.
 * @returns {void}
 */
function showOrHideScrollToTop() {
  const pageIsAtTheTop = window.scrollY === 0;
  const scrollToTop = document.querySelector(SCROLL_TO_TOP_SELECTOR);
  const scrollToTopIsVisible = scrollToTop.classList.contains(
    SCROLL_TO_TOP_VISIBLE_CLASS
  );
  const hideScrollToTop = pageIsAtTheTop && scrollToTopIsVisible;
  const showScrollToTop = !pageIsAtTheTop && !scrollToTopIsVisible;

  if (hideScrollToTop) {
    scrollToTop.classList.remove(SCROLL_TO_TOP_VISIBLE_CLASS);
  } else if (showScrollToTop) {
    scrollToTop.classList.add(SCROLL_TO_TOP_VISIBLE_CLASS);
  }
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const scrollToTopInPage = document.querySelector(SCROLL_TO_TOP_SELECTOR);

  if (!scrollToTopInPage) return;

  window.addEventListener('scroll', showOrHideScrollToTop);
  showOrHideScrollToTop();
});
